//variables
$orange: #ff7700;

//font faces
@font-face {
  font-family: "Arial black";
  src: url("/fonts/arial-black.ttf");
}

@font-face {
  font-family: "Helvetica";
  src: url("/fonts/helvetica.ttf");
}

main,
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  font-family: "Helvetica", sans-serif;
  background-color: #e1e1e1 !important;
  scroll-behavior: auto !important; // Required to avoid smooth scrolling.
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.orange {
  background-color: $orange;
}

.pointer {
  cursor: pointer;
}

.bg-gray {
  background-color: #e1e1e1;
}

.text-orange {
  color: $orange;
}

.bg-orange {
  background-color: $orange  !important;
}

.border-orange {
  border-color: $orange;
}

.white-to-black-btn {
  display: block;
  background-color: transparent;
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 3rem;
  font-weight: bold;
  font-size: larger;

  transition: all 100ms ease;

  &:hover {
    background-color: black;
    border-color: black;
  }
}

.white-to-orange-btn {
  display: block;
  background-color: transparent;
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 3rem;
  font-weight: bold;
  font-size: larger;

  transition: all 100ms ease;

  &:hover {
    background-color: $orange;
    border-color: $orange;
  }
}

.black-to-orange-btn {
  display: block;
  background-color: transparent;
  color: black;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 3rem;
  font-weight: bold;
  font-size: larger;

  transition: all 100ms ease;

  &:hover {
    color: white;
    background-color: $orange;
    border-color: $orange;
  }
}

.orange-to-black-btn {
  display: block;
  background-color: $orange;
  color: white;
  border-color: $orange;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 3rem;
  font-weight: bold;
  font-size: larger;

  transition: all 100ms ease;

  &:hover {
    color: white;
    background-color: black;
    border-color: black;
  }
}

.fs-header {
  font-size: 40px;

  @media (min-width: 768px) {
    font-size: 56px;
  }
}

.project__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;

  :first-child {
    grid-column: 1 / 3;
  }

  :nth-child(3n + 4) {
    grid-column: 1 / 3;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    :first-child {
      grid-column: 1 / 3;
    }

    :nth-child(3n + 4) {
      grid-column: inherit;
    }

    :nth-child(10n + 11) {
      grid-column: 1 / 3;
    }

    :nth-child(10n + 7) {
      grid-column: 2 / 4;
    }
  }

}

//swiper
.swiper {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.swiper-slide {
  max-width: 35em;
  width: 100%;
  max-height: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next {
  background-image: url('/arrow-right-orange.png');
  background-repeat: no-repeat;
  background-size: 70% auto;
  background-position: center;
  background-color: transparent;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url('/arrow-right-orange.png');
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-size: 70% auto;
  background-position: center;
  background-color: transparent;
}

.swiper-button-prev::after {
  display: none;
}

//bbotstrapi accordion
.accordion-button:not(.collapsed)::after {
  background-image: url('/arrow-down.png') !important;
  transform: rotate(180deg);
}

.accordion-button.collapsed::after {
  background-image: url('/arrow-down.png') !important;
}

.accordion-body {
  background-color: #e1e1e1 !important;
}

//underline on hover
.hover-underline:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.arial-black {
  font-family: 'Arial black', sans-serif;
}

.hover-black:hover {
  color: black !important;
}

.footer-text-align {
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}

.uppercase {
  text-transform: uppercase;
}

.hover-orange {
  &:hover {
    color: $orange !important;
  }
}

// Overrido of non working expansion panel arrows.
.collapsed::after {
  display: none;
}

.accordion-button::after {
  display: none;
}

// Debug
// * {
//   outline: 1px solid red;
// }